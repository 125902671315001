import React, { useState, useContext } from "react"
import Hero from "../components/Hero"
import Experts from "../components/Experts"
import Seo from "../components/seo"
import { useAuthContext } from "../hooks/useAuthContext"
import { graphql } from "gatsby"
import { StateContext } from "../helpers/StateProvider"
import exportIcon from "../assets/icons/architect.svg";
import expertImage from "../assets/images/architect.webp";
const AppraisalSpecialist = props => {
  const { authIsReady } = useAuthContext()
  let inverted = true
  const { language, setLanguage } = useContext(StateContext)

  return (
    <>
      {props.data.allPropertyType.edges && props.data.allPropertyType.edges && (
        <>
          <Seo title="Appraisal Specialist" />
          <Hero
            arabic={language}
            title={
              language == false
                ? "Appraisal Specialist"
                : "أخصائي تقييم"
            }
            subTitle={
              language == false
                ? "Valuing Your Assets: Precision and Expertise in Appraisal Services"
                : "تقييم أصولك: الدقة والخبرة في خدمات التقييم"
            }
          />
          {/* <Widgets widgetsData='property-type'/>  */}

      
              <Experts
                inverted={false}
                Icon={exportIcon}
                image={expertImage}
                title={
                  language == false
                    ? "Appraisal Specialist"
                    : "أخصائي تقييم"
                }
                description={
                  language == false
                    ? "our appraisal specialists bring a meticulous approach to the valuation process, combining industry expertise, in-depth research, and the latest market insights to provide accurate and reliable appraisals. Whether you're a homeowner, investor, or part of a large corporation, our goal is to empower you with precise information, enabling informed decisions about your property and assets."
                    : "يقدم متخصصو التقييم لدينا نهجًا دقيقًا لعملية التقييم، ويجمعون بين الخبرة الصناعية والأبحاث المتعمقة وأحدث رؤى السوق لتقديم تقييمات دقيقة وموثوقة. سواء كنت مالك منزل، أو مستثمرًا، أو جزءًا من شركة كبيرة، فإن هدفنا هو تزويدك بمعلومات دقيقة، مما يتيح لك اتخاذ قرارات مستنيرة بشأن ممتلكاتك وأصولك."
                }
                label={
                  language == false
                  ? "Call Our Appraisal Specialist"
                  : "اتصل بأخصائي التقييم لدينا"
                }
                link={"tel:+9613948739"}
                arabic={language}
              />
          <br/><br/><br/>
        </>
      )}
    </>
  )
}

export const query = graphql`
  {
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
    allPage(filter: { name: { eq: "property-type" } }) {
      edges {
        node {
          name
          title
          title_ar
          id
          description
          description_ar
          widgets {
            name
            type
          }
        }
      }
    }
    allPropertyType {
      edges {
        node {
          description
          description_ar
          id
          image
          link
          title
          title_ar
          value
          subcategories {
            label
            label_ar
            value
          }
          localImage {
            childImageSharp {
              fixed(width: 1000) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
  }
`
export default AppraisalSpecialist
